<template>

<div>

	<com-title text="Billing" />

	<template v-if="$convention">

		<com-item route="Convention.Billing.Overview" text="Overview" />
		<com-item route="Convention.Billing.Transactions" text="Transactions" />
		<com-item route="Convention.Billing.Fees" text="Fees" />
		<com-item route="Convention.Billing.Payouts" text="Payouts" />

	</template>

	<template v-if="!$convention">

		<com-item route="Organisation.Billing.Overview" text="Overview" />
		<com-item route="Organisation.Billing.Transactions" text="Transactions" />
		<com-item route="Organisation.Billing.Fees" text="Fees" />
		<com-item route="Organisation.Billing.Payouts" text="Payouts" />
		<com-item route="Organisation.Billing.Settings" text="Settings" />

	</template>

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../..//panel/common/Title'),
		'com-item': () => import('./../..//panel/common/Item')
	}

}

</script>

<style scoped>

</style>
